<template>
  <div class="freeOffers container">
    <base-filters
      v-if="offersFilters"
      :filters="offersFilters"
      @locationvalue="locationValue"
      @categoryvalue="categoryValue"
      @searchvalue="searchvalue"
    />
    <div v-if="$apollo.loading && this.isFirst">
      <base-skeleton-loader type="offer" :count="8"></base-skeleton-loader>
    </div>
    <div class="row" v-else>
      <div
        class="col-xl-3 col-lg-4 col-sm-6"
        v-for="(offer, i) in offers"
        :key="i"
      >
        <offers-card :offer="offer"></offers-card>
      </div>
      <infinite-loading
        @infinite="infiniteHandler"
        :identifier="infiniteId"
        :distance="30"
        v-if="!isFirst"
      >
        <!-- <div slot="spinner">
          <base-skeleton-loader type="offer" :count="8"></base-skeleton-loader>
        </div>
        <div slot="no-more"></div> -->
      </infinite-loading>
    </div>
    <div v-if="offers.length == 0" class="no-record">No Record Found</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { OFFERS } from "@/graphql/user/query";
export default {
  components: {
    OffersCard: () => import("@/components/user/OffersCard.vue"),
  },
  data() {
    return {
      offers: [],
      filterLocations: null,
      filterCategories: null,
      search: "",
      page: 1,
      limit: 16,
      infiniteId: +new Date(),
      hasMore: true,
      isFirst: true,
    };
  },
  apollo: {
    offers: {
      query: OFFERS,
      variables() {
        return {
          page: 1,
          limit: this.limit,
          locations:
            this.offersFilters.default != null
              ? this.$store.offersFilters.default.locations.map(
                  (item) => item.id
                )
              : null,
          categories:
            this.offersFilters.default != null
              ? this.$store.offersFilters.default.categories.map(
                  (item) => item.id
                )
              : null,
          search: this.search,
        };
      },
    },
  },
  computed: {
    ...mapGetters("filters", ["offersFilters"]),
  },
  watch: {
    offers() {
      this.isFirst = false;
    },
  },
  methods: {
    locationValue(data) {
      this.filterLocations = data;
      let newFilter = this.filterLocations.map((item) => item.id);
      if (this.filterLocations.length == 0) {
        this.filterLocations = null;
      } else {
        this.filterLocations = newFilter;
      }
      this.refetch();
    },
    categoryValue(data) {
      this.filterCategories = data;

      let newFilter = this.filterCategories.map((item) => item.id);

      if (this.filterCategories.length == 0) {
        this.filterCategories = null;
      } else {
        this.filterCategories = newFilter;
      }
      this.refetch();
    },
    searchvalue(data) {
      this.search = data;
      this.$apollo.queries.offers.skip = false;
      this.refetch();
    },
    refetch() {
      if (!this.isFirst) {
        this.page = 1;
        this.infiniteId += 1;
        this.$apollo.queries.offers.refetch({
          page: this.page,
          limit: this.limit,
          locations: this.filterLocations,
          categories: this.filterCategories,
          search: this.search,
        });
      }
    },
    infiniteHandler($state) {
      if (this.hasMore) {
        setTimeout(() => {
          try {
            this.page++;
            this.$apollo.queries.offers.fetchMore({
              variables: {
                page: this.page,
                limit: this.limit,
                locations: this.filterLocations,
                categories: this.filterCategories,
                search: this.search,
              },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                const newOffers = fetchMoreResult.offers;
                if (newOffers.length) {
                  $state.loaded();
                } else {
                  $state.complete();
                  this.hasMore = false;
                }
                if (newOffers.length < this.limit) {
                  this.hasMore = false;
                }
                return {
                  offers: [...previousResult.offers, ...newOffers],
                };
              },
            });
          } catch (err) {
            console.log("fetchMore error", JSON.stringify(err));
          }
        }, 500);
      } else {
        $state.complete();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
